<template>
    <v-container>
        <v-layout row justify-center>
            <v-subheader
                v-if="$vuetify.breakpoint.smAndDown && !billingAccountId"
                class="my-2 full-width"
                @click="backToMyBillingAccounts"
            >
                <v-btn icon>
                    <v-icon>{{ '$vuetify.icons.chevronLeft' }}</v-icon>
                </v-btn>
                Create Billing Account
            </v-subheader>
            <v-form v-model="valid" class="full-width">
                <v-list color="transparent" subheader two-line flat>
                    <v-list-item v-if="!validAbn">
                        <v-alert v-if="!validAbn" dense type="warning" text>
                            Invalid ABN.
                        </v-alert>
                    </v-list-item>

                    <v-list-item>
                        <v-text-field
                            v-model="form.contact_name"
                            label="Billing Contact Name"
                            prepend-icon="$vuetify.icons.profile"
                            required
                            :rules="[required]"
                            @input="formChange"
                        />
                    </v-list-item>

                    <v-list-item>
                        <v-text-field
                            v-model="form.email"
                            label="Billing Email"
                            prepend-icon="$vuetify.icons.email"
                            required
                            :rules="[email]"
                            @input="formChange"
                        />
                    </v-list-item>

                    <v-list-item>
                        <v-text-field
                            v-model="form.company_name"
                            label="Company Name"
                            prepend-icon="$vuetify.icons.info"
                            required
                            :rules="[required]"
                            @input="formChange"
                        />
                    </v-list-item>

                    <v-list-item>
                        <v-text-field
                            v-model="form.company_address"
                            label="Company Address"
                            prepend-icon="$vuetify.icons.companyAddress"
                            required
                            :rules="[required]"
                            @input="formChange"
                        />
                    </v-list-item>

                    <v-list-item>
                        <v-select
                            v-model="form.company_industry"
                            label="Company Industry"
                            prepend-icon="$vuetify.icons.briefCase"
                            required
                            :items="companyIndustryOptions"
                            item-text="text"
                            item-value="value"
                            :rules="[required]"
                            @input="formChange"
                        />
                    </v-list-item>

                    <v-list-item>
                        <v-select
                            v-model="form.company_size"
                            label="Company Size"
                            prepend-icon="$vuetify.icons.team"
                            required
                            :items="companySizeOptions"
                            item-text="text"
                            item-value="value"
                            :rules="[required]"
                            @input="formChange"
                        />
                    </v-list-item>

                    <v-list-item>
                        <v-text-field
                            v-model="form.abn"
                            label="ABN"
                            prepend-icon="$vuetify.icons.building"
                            required
                            @input="formChange"
                        />
                    </v-list-item>
                </v-list>

                <v-layout justify-center>
                    <v-btn
                        v-if="billingAccountId"
                        text
                        color="primary"
                        @click="clear"
                        >Cancel</v-btn
                    >
                    <v-spacer />
                    <v-btn
                        large
                        color="primary"
                        :disabled="!valid"
                        :loading="buttonLoading"
                        @click="submit"
                    >
                        {{ submitButtonText }}
                        <v-icon right>{{ '$vuetify.icons.next' }}</v-icon>
                    </v-btn>
                </v-layout>
            </v-form>

            <generic-snack-bar ref="snackbar" />
        </v-layout>
    </v-container>
</template>

<script>
import axios from 'axios';
import GenericSnackBar from 'components/GenericSnackbar/GenericSnackBar.vue';

export default {
    name: 'BillingAccountForm',
    components: { GenericSnackBar },
    props: {
        value: {
            type: Object,
            default: null
        },
        billingAccountId: {
            type: String,
            default: null
        },
        planId: {
            type: String,
            default: null
        }
    },
    data: function() {
        return {
            buttonLoading: false,
            buttonDisable: true,
            form: {
                contact_name: null,
                email: null,
                company_name: null,
                company_address: null,
                company_industry: null,
                company_size: null,
                abn: null
            },
            validAbn: true,
            valid: null,
            snackbar: false,
            snackbarMessage: '',
            email: value => {
                const pattern = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\d-A-Za-z]+\.)+[A-Za-z]{2,}))$/;
                return pattern.test(value) || 'Must be a valid e-mail address.';
            },
            required: value => !!value || 'Required field.',
            companyIndustryOptions: [
                { text: 'Agriculture and Farming', value: 'ag/farm' },
                { text: 'Asset Management and Maintenance', value: 'asset' },
                { text: 'Building and Construction', value: 'building' },
                { text: 'Councils and Government', value: 'councils/gov' },
                { text: 'Drones & Aerial Services', value: 'service' },
                { text: 'Emergency Services', value: 'emergency' },
                { text: 'Energy (Renewable)', value: 'energy-renew' },
                { text: 'Energy (Oil and Gas)', value: 'energy-oil/gas' },
                { text: 'Energy (Other Types)', value: 'energy-other' },
                { text: 'Infrastructure', value: 'infra' },
                { text: 'Insurance and Compliance', value: 'insurance' },
                { text: 'Mining and Resources', value: 'mine/res' },
                { text: 'Real Estate', value: 'realestate' },
                { text: 'Telecommunications', value: 'telco' },
                { text: 'All Other Industries', value: 'other' }
            ],
            companySizeOptions: [
                { value: '1', text: 'Just Me' },
                { value: 'small', text: '2-10' },
                { value: 'medium', text: '10-50' },
                { value: 'large', text: '50-200' },
                { value: 'corporate', text: '200+' }
            ]
        };
    },
    computed: {
        submitButtonText: function() {
            if (this.billingAccountId) return 'Save';
            return 'Submit';
        }
    },
    watch: {
        // validates the abn field according to the ATO supplied formula
        'form.abn': function(value) {
            value = value.replace(/[ _]/g, '');

            if (value.length === 0) {
                this.validAbn = true;
                return;
            }

            if (value.length !== 11) {
                this.validAbn = false;
                return;
            }

            const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
            let checksum = value
                .split('')
                .map(Number)
                .reduce((total, digit, index) => {
                    if (!index) digit--;
                    return total + digit * weights[index];
                }, 0);

            if (!checksum || checksum % 89 !== 0) {
                this.validAbn = false;
                return;
            }
            this.validAbn = true;
        }
    },
    mounted() {
        if (this.billingAccountId) {
            this.refreshValues();
        }
    },
    methods: {
        formChange() {
            this.buttonDisable = false;
        },
        createBillingAccount() {
            this.buttonLoading = true;
            let billingInfo = this.form;

            let postBody = JSON.stringify({
                billingAccount: billingInfo,
                planId: this.planId
            });

            axios
                .post(`/api/billing/create/`, postBody)
                .then(response => response.data)
                .then(data => {
                    this.buttonLoading = false;
                    this.$refs.snackbar.show(data.message);
                    if (data.success) {
                        this.$refs.snackbar.show(
                            'Successfully created your billing account.'
                        );

                        window.location.reload();

                        this.$emit('input', {
                            billingAccountId: data.accountId,
                            billingAccountInfo: data.billingInfo
                        });
                    } else {
                        this.$refs.snackbar.show(
                            "Your billing account couldn't be created. Please try again."
                        );
                    }
                })
                .catch(() => {
                    this.buttonLoading = false;
                    this.$refs.snackbar.show(
                        "Your billing account couldn't be created. Please try again."
                    );
                });
        },
        editBillingAccount() {
            this.buttonLoading = true;
            axios
                .post(
                    `/api/billing/${this.billingAccountId}/edit/`,
                    JSON.stringify({ billingAccount: this.form })
                )
                .then(response => response.data)
                .then(data => {
                    this.buttonLoading = false;
                    this.$refs.snackbar.show(data.message);
                })
                .catch(() => {
                    this.buttonLoading = false;
                    this.$refs.snackbar.show(
                        "Your changes couldn't be saved. Please try again."
                    );
                });
        },
        submit() {
            if (!this.billingAccountId) {
                this.createBillingAccount();
            } else {
                this.editBillingAccount();
            }
        },
        refreshValues() {
            axios
                .get(
                    `${window.location.origin}/api/billing_account/${this.billingAccountId}/get_billing_account/`
                )
                .then(response => response.data)
                .then(data => {
                    Object.assign(this.form, data);
                })
                .catch(() => {
                    this.$refs.snackbar.show(
                        'The form could not be refreshed.'
                    );
                });
        },
        clear() {
            this.$emit('cancel');
            if (this.billingAccountId) {
                this.refreshValues();
            } else {
                this.form = {
                    contact_name: null,
                    email: null,
                    company_name: null,
                    company_address: null,
                    company_industry: null,
                    company_size: null,
                    abn: null
                };
            }
        },
        backToMyBillingAccounts() {
            this.$emit('tab-navigation');
        }
    }
};
</script>

<style scoped />
