<template>
    <v-container class="payment-row">
        <v-row>
            <!-- We'll put the error messages in this element -->
            <div id="card-errors" role="alert"></div>

            <br />

            <div id="card-element" class="MyCardElement">
                <!-- Elements will create input elements here -->
            </div>

            <div v-if="isUpdatePage" id="upgrade-plan" class="">
                <v-checkbox
                    v-if="currentBillingHasPlan"
                    v-model="upgradePlan"
                    label="Do you want to also upgrade plan?"
                ></v-checkbox>
                <v-checkbox
                    v-else
                    v-model="newUser"
                    :rules="validateNewUserPlanCheckbox"
                    label="New Subcriber"
                    required
                ></v-checkbox>
            </div>
            <v-container>
                <v-select
                    v-if="showPlans"
                    v-model="subscriptionPlan"
                    :items="subscriptionPlans"
                    item-text="name"
                    item-value="stripe_plan_id"
                    label="Choose a subscription plan"
                    @change="planSelected"
                >
                </v-select>
            </v-container>
        </v-row>

        <v-row align-content="end">
            <v-dialog v-model="dialog" width="500">
                <template #activator="{ on }">
                    <a class="pt-10" v-on="on">Security Information</a>
                </template>

                <v-card>
                    <v-card-title class="headline" primary-title>
                        Security Information
                    </v-card-title>

                    <v-card-text>
                        Your credit card information is handled by Stripe's
                        secure payment system. Stripe is an industry leading
                        payment provider and is fully compliant with all the
                        relevant standards such as PCI. Smartdata does not
                        process or keep a copy of your credit card information
                        at any time except for the last 4 digits of your card
                        number and the expiry, so that we can display them to
                        you.
                    </v-card-text>

                    <v-divider />

                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" text @click="dialog = false">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-spacer />

            <v-btn
                class="mt-5"
                large
                color="primary"
                :loading="btn_loading"
                :disabled="!btn_enabled"
                @click="getPaymentMethod"
            >
                {{ btn_text }}
                <v-icon right>{{ '$vuetify.icons.next' }} </v-icon>
            </v-btn>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';

export default {
    name: 'StripeForm',
    props: {
        billingInfo: {
            type: Object,
            default: function() {
                return null;
            }
        },
        isUpdateForm: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
        billingPlans: {
            type: Array,
            default: function() {
                return [];
            }
        }
    },
    data: function() {
        return {
            btn_enabled: !String(window.location.href).includes(
                '/accounts/plan/select'
            )
                ? false
                : true,
            btn_loading: false,
            dialog: false,
            stripe: null,
            elements: null,
            cardElement: null,
            upgradePlan: false,
            newUser: false,
            subscriptionPlans: this.billingPlans,
            subscriptionPlan: null,
            currentBillingHasPlan: this.billingInfo?.hasPlan,
            isUpdatePage: true
        };
    },
    computed: {
        btn_text() {
            return !this.isUpdateForm || !this.currentBillingHasPlan
                ? 'Start Trial'
                : 'Update Card';
        },
        showPlans() {
            return this.upgradePlan || this.newUser ? true : false;
        },
        validateNewUserPlanCheckbox() {
            this.upgradePlan || this.newUser
                ? (this.subscriptionPlan = null)
                : (this.btn_enabled = false);
            return [
                this.currentBillingHasPlan == true ||
                    'This is required. Current billing account has no billing plan and subscribing for the first time'
            ];
        }
    },
    mounted() {
        // check if it's update page or user choose plan page
        if (String(window.location.href).includes('/accounts/plan/select')) {
            this.isUpdatePage = false;
            this.btn_enabled = true;
        }

        // check if the user has no plan and deactivate boon otherwise activate the button
        if (this.currentBillingHasPlan) {
            this.btn_enabled = true;
        }

        // eslint-disable-next-line no-undef
        this.stripe = window.Stripe(window.NDSD_STRIPE_PK);
        this.elements = this.stripe.elements();

        const style = {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };

        this.cardElement = this.elements.create('card', { style: style });
        setTimeout(() => {
            this.cardElement.mount('#card-element');
        }, 500);
    },
    methods: {
        planSelected() {
            this.btn_enabled = true;
        },
        subscriptionConfirmed() {
            /**
             * Alerts and redirects the user when the subscription has been confirmed.
             */
            this.btn_enabled = false;
            this.btn_loading = false;
            window.alert('Your card was successfully verified.');
            if (!this.isUpdateForm) window.location = '/';
            else window.location.reload();
        },
        savePaymentMethod(paymentMethodObject) {
            /**
             * Saves the payment method to our DB and creates a subscription if necessary. Will also trigger
             * a 3DS authentication window (due to SCA) if it's needed.
             */
            axios
                .post(`/api/billing/payment/save`, paymentMethodObject)
                .then(response => response.data)
                .then(data => {
                    if (data.status === 'success') {
                        this.subscriptionConfirmed();
                    } else if (data.status === 'requires_action') {
                        // eslint-disable-next-line promise/no-nesting
                        this.stripe
                            .confirmCardPayment(data.secret)
                            .then(result => {
                                if (result.error) {
                                    this.btn_loading = false;
                                    window.alert(result.error.message);
                                    // TODO: make this nicer
                                } else {
                                    // If the user successfully auths the 3DS activate their account
                                    axios
                                        .post('/api/billing/payment/activated')
                                        .then(() => {
                                            this.subscriptionConfirmed();
                                        })
                                        .catch(() => {
                                            window.alert(
                                                'There was an error activating your account. Please contact ' +
                                                    'support.'
                                            );
                                        });
                                }
                            })
                            .catch(error => {
                                this.btn_loading = false;
                                window.alert(
                                    'There was an error saving your payment method.'
                                );
                                // TODO: make this nicer
                                throw error;
                            });
                    } else {
                        this.btn_loading = false;
                        window.alert(
                            `There was an error while saving your payment method. ${data.status}`
                        );
                    }
                })
                // eslint-disable-next-line sonarjs/no-identical-functions
                .catch(error => {
                    this.btn_loading = false;
                    window.alert(
                        'There was an error saving your payment method.'
                    );
                    // TODO: make this nicer
                    throw error;
                });
        },
        getPaymentMethod() {
            /**
             * Gets a new payment method from the user using Stripe elements and Stripe.js
             */
            // Set subscription default plan to current plan if it's not an upgrade
            if (
                !String(window.location.href).includes('/accounts/plan/select')
            ) {
                if (
                    this.currentBillingHasPlan &&
                    this.billingInfo.plan.stripe_plan_id != null &&
                    !this.upgradePlan &&
                    this.subscriptionPlan == null
                ) {
                    this.subscriptionPlan = this.billingInfo.plan.stripe_plan_id;
                }

                if (
                    !this.currentBillingHasPlan &&
                    this.subscriptionPlan == null
                ) {
                    window.alert(
                        'Plan is required. Kindly choose a plan to update billing account.'
                    );
                    return true;
                }
            }

            this.btn_loading = true;
            this.stripe
                .createPaymentMethod({
                    type: 'card',
                    card: this.cardElement,
                    billing_details: {
                        email: this.billingInfo.email,
                        name: this.billingInfo.contact_name
                    }
                })
                .then(result => {
                    if (result.error) {
                        throw result.error;
                    }

                    // Handle result.error or result.paymentMethod
                    result = result.paymentMethod;

                    let paymentMethod = {
                        billingAccountId: this.billingInfo.billingAccountId,
                        upgradePlan: this.upgradePlan,
                        newUser: this.newUser,
                        subscriptionPlan: this.subscriptionPlan,
                        id: result.id,
                        card: {
                            last4: result.card.last4,
                            exp:
                                result.card.exp_month +
                                '/' +
                                result.card.exp_year,
                            country: result.card.country,
                            brand: result.card.brand
                        }
                    };

                    this.savePaymentMethod(paymentMethod);
                })
                .catch(error => {
                    this.btn_loading = false;
                    window.alert(
                        'There was an error verifying your payment method.'
                    );
                    // TODO: make this nicer
                    throw error;
                });
        }
    }
};
</script>

<style scoped>
.MyCardElement {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.MyCardElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.MyCardElement--invalid {
    border-color: #fa755a;
}

.MyCardElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.payment-row {
    max-width: 400px;
}
</style>
